.aboutUs-logo {
    width: 100px;
    height: 100px;
}

.aboutUs-para {
    font-weight: 500;
    font-style: normal;
    white-space: pre-wrap;
    color: rgb(98, 107, 127);
    line-height: 1.7em;
    font-size: 16px;
    text-decoration-line: none;
}