.cart-summary {
    border: 1px solid rgb(207, 207, 207);
    padding: 15px;
}

.cart-summary-para {
    color: rgb(102, 102, 102);
}

.cart-summary-voucher {
    background-color: rgb(237, 237, 237);
}

.order-now-btn {
    height: 50px;
    width: 100%;
    background-color: #e4510b;
    border: none;
    color: white;
    transition: transform 0.3s, box-shadow 0.3s;
}

.order-now-btn:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    background-color: #df4700;
}

.cart-card {
    border: 1px solid rgb(207, 207, 207);
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.cart-title {
    color: #333333;
}

.cart-left {
    background-size: cover;
}

.cart-quantity {
    color: rgb(102, 102, 102);
}



@media (max-width: 768px) {
    .cart-card {
        flex-direction: column;
    }

    .cart-left {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%;
        height: 200px;
    }
}

.empty-card-img {
    height: 400px;
}

@media (max-width: 768px) {
    .empty-card-img {
        height: 300px;
    }
}

.editQuantityInput {
    height: 40px;
}

.editquantityBtn {
    height: 40px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: green;
}



@media (max-width: 768px) {
    .shiping {
        margin-top: 0;
    }
}




.ShippingAdress {
    padding: 5px;
}

/* rating stars */

.rating {
    display: inline-block;
    margin-top: -15px;
}

.rating input {
    display: none;
}

.rating label {
    float: right;
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s;
}

.rating label:before {
    content: '\2605';
    font-size: 30px;
    display: inline-block;
}

.rating input:checked~label,
.rating label:hover,
.rating label:hover~label {
    color: rgb(253, 181, 66);
    transition: color 0.3s;
}