.drawing-area {
  position: absolute;
  z-index: 10;
  border: 1px solid #ccc;
}

.canvas-container {
  position: relative;
  user-select: none;
}

#tshirt-div {
  position: relative;
  background-color: #fff;
}

#canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  user-select: none;
  cursor: default;
}

.customize-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
}

.heading-product {
  font-size: 30px;
  padding: 0px;
  margin: 0px;
}

.btn-save-customize {
  background-color: #e44324;
  height: 41px;
  border: none;
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 4px;
}

.customize-reset {
  border: black 1px solid;
  height: 41px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 5px;
}

.customize-question {
  border: none;
  height: 41px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 5px;
}

.customize-product-container {
  background-color: #f0f0f0;
  height: 94vh;
  display: flex;
  flex-direction: row;
}

.left-container-img {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-container-edit {
  width: 400px;
  background-color: white;
  padding: 20px;
}

.select-customize-label {
  font-size: 14px;
}

.customize-select {
  width: 100%;
  height: 40px;
}

@media (max-width: 568px) {
  .customize-header {
    flex-direction: column;
    height: 100%;
  }

  .customize-product-container {
    flex-direction: column;
    height: 100%;
  }

  .left-container-img {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right-container-edit {
    width: 100%;
  }
}

@media (max-width:786px) {
  .customize-product-container {
    flex-direction: column;
    height: 100%;
  }

  .left-container-img {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right-container-edit {
    width: 100%;
  }
}
.vertical-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-images img {
  margin-bottom: 10px; 
  cursor: pointer; 
}