.btn1{
    background: #e44324;
    color: white;
    width: 200px;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
}
.custom{
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.customint{
    width: 100%;
}
.customint h1{
    font-size: 50px;
}
.customint p{
    font-size: 19px;
}