/* ImageSlider.css */
.image-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thumbnail-container {
    display: flex;
    margin-bottom: 10px;
    overflow: hidden;
}

.thumbnail-container img {
    width: 100%;
    height: 100px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid #ccc;
}

.selected-thumbnail {
    border: 3px solid rgb(0, 0, 0) !important;
    /* Highlight the selected thumbnail */
}

.main-image-container {
    display: flex;
    justify-content: center;
}

.main-image {
    max-width: 100%;
    max-height: 500px;
    margin-bottom: 15px;
    /* Adjust the max height as needed */
}


@media (max-width:748px) {
    .thumbnail-container img {
        width: 60px;
        height: 40px;
        margin-right: 10px;
        cursor: pointer;
        border: 2px solid #ccc;
    }
}

.slider img {
    /* width: 100%; */
    border-radius: 10px;
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: rgb(255, 68, 68);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: rgb(255, 68, 68) !important;
}

.imagecenter {
    width: 200px !important;
    height: 200px;
}

.carousel-control-next-icon {
    display: none !important;
}

.carousel-control-prev-icon {
    display: none !important;
}

.carousel-indicators {
    bottom: 5%;
}

.main-image {
    position: relative;
}