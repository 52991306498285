.con-like {
    --red: rgb(255, 50, 50);
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 5%;
    margin-top: 5px;
    margin-left: auto;
}

.con-like .like {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}

.con-like .checkmark {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.con-like .outline,
.con-like .filled {
    fill: var(--red);
    position: absolute;
}

.con-like .filled {
    animation: kfr-filled 0.5s;
    display: none;
}

.con-like .celebrate {
    position: absolute;
    animation: kfr-celebrate 0.5s;
    animation-fill-mode: forwards;
    display: none;
}

.con-like .poly {
    stroke: var(--red);
    fill: var(--red);
}

.con-like .like:checked~.checkmark .filled {
    display: block
}

.con-like .like:checked~.checkmark .celebrate {
    display: block
}

@keyframes kfr-filled {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes kfr-celebrate {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 0.8;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
        display: none;
    }
}